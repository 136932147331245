import { useEffect, useRef, useState } from 'react'

import {
  CheckCircleOutlined,
  CheckOutlined,
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { ClockCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { Select, Skeleton, TextLabel } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { GetBrokersByIdDto } from '@/App/clients/healthPlansOrder/dto'
import useFetch from '@/App/clients/http'

import PaymentInformation from './paymentInformationAccordion'
import { SkeletonCard } from './paymentInformationAccordion/style'
import PersonalInformation from './personalInformationAccordion'
import RegisteredOperators from './registeredOperatorsAccordion'
import {
  BrokerInfo,
  BrokerStatus,
  Column,
  CreatedAt,
  EditableButton,
  Header,
  InternalSystemIdTextInput,
  IsRegisteredInInternalSystem,
  IsRegisteredInInternalSystemDiv,
  ModalBody,
  SkeletonBrokerInfo,
  SkeletonColumn,
  SkeletonWrapper,
  StyledOptionLabel,
  TextLabelDiv,
} from './style'

export interface Props {
  brokerId: string
}

const BrokerModalContent: React.FC<Props> = ({ brokerId }) => {
  const [brokersData, setBrokersData] = useState<GetBrokersByIdDto | null>(null)
  const [registrationStatus, setRegistrationStatus] = useState('')
  const [internalSystemId, setInternalSystemId] = useState(brokersData?.internalSystemId)
  const [isEditable, setIsEditable] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)

  const getBrokerByIdRequestConfig = healthPlansOrderRequest.getBrokersById(brokerId)

  const { setRequestConfig, data, isLoading, error } = useFetch<GetBrokersByIdDto>()

  const {
    setRequestConfig: fillBrokerRegistrationStatusRequestConfig,
    isLoading: isLoadingBrokerRegistrationStatus,
    error: registrationStatusError,
  } = useFetch<GetBrokersByIdDto>()

  const {
    setRequestConfig: fillBrokerInternalSystemIdRequestConfig,
    isLoading: isLoadingInternalSystemId,
    error: internalSystemIdError,
  } = useFetch<GetBrokersByIdDto>()

  const fillBrokerRegistrationStatus = (data) => {
    const fillBrokerInformation = healthPlansOrderRequest.fillBrokerInformation(brokerId, data)
    fillBrokerRegistrationStatusRequestConfig(fillBrokerInformation)
  }

  const fillBrokerInternalSystemId = (data) => {
    const parsedData = {
      ...data,
      isRegisteredInInternalSystem: data?.internalSystemId != null,
    }
    const fillBrokerInformation = healthPlansOrderRequest.fillBrokerInformation(
      brokerId,
      parsedData,
    )
    fillBrokerInternalSystemIdRequestConfig(fillBrokerInformation)
  }

  useEffect(() => {
    const hasErrror = error || registrationStatusError || internalSystemIdError
    const isDuplicatedBrokerIdError =
      !!internalSystemIdError?.body?.message.includes('Broker with internal Id')

    if (isDuplicatedBrokerIdError) {
      triggerToast(false, 'Codigo do sisweb já utilizado por outro corretor')
      setInternalSystemId(brokersData?.internalSystemId ?? '')
    } else if (hasErrror) {
      triggerToast(false, 'Erro ao atualizar os dados do corretor')
    }
  }, [error, registrationStatusError, internalSystemIdError])

  useEffect(() => {
    if (data)
      setBrokersData(data),
        setRegistrationStatus(data.registrationStatus),
        setInternalSystemId(data.internalSystemId)
  }, [data])

  useEffect(() => {
    setRequestConfig(getBrokerByIdRequestConfig)
  }, [brokerId])

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditable])

  const translatedOptions = () => {
    const statusTranslations = {
      PENDING: {
        label: 'Pendente',
        color: 'orange',
        icon: <ClockCircleOutlined />,
      },
      COMPLETE: {
        label: 'Completo',
        color: '#42A859',
        icon: <CheckCircleOutlined />,
      },
    }

    return Object.entries(statusTranslations).map(([key, { label, color, icon }]) => ({
      value: key,
      label: (
        <>
          {icon}
          <StyledOptionLabel>{label}</StyledOptionLabel>
        </>
      ),
      color,
      icon,
    }))
  }
  return (
    <>
      <Header>
        {isLoading ? (
          <>
            <SkeletonBrokerInfo>
              <Skeleton type="button" />
              <SkeletonColumn>
                <Skeleton type="button" />
                <Skeleton type="button" />
              </SkeletonColumn>
            </SkeletonBrokerInfo>
          </>
        ) : (
          <>
            <BrokerInfo>
              <CreatedAt>
                Cadastrado em: {dayjs(brokersData?.createdAt).locale('pt-br').format('DD/MM/YYYY')}
              </CreatedAt>
              <TextLabelDiv>
                <TextLabel
                  text={brokersData?.name}
                  leftIcon={<UserOutlined />}
                  rightIconType="copy"
                ></TextLabel>
              </TextLabelDiv>
              <Column>
                <TextLabelDiv>
                  <TextLabel
                    text={brokersData?.personalInformation.mail}
                    leftIcon={<MailOutlined />}
                    rightIconType="copy"
                  ></TextLabel>
                </TextLabelDiv>
                <TextLabelDiv>
                  <TextLabel
                    text={brokersData?.personalInformation.phone}
                    type="phone"
                    leftIcon={<PhoneOutlined />}
                    rightIconType="copy"
                  ></TextLabel>
                </TextLabelDiv>
              </Column>
            </BrokerInfo>
          </>
        )}

        {isLoading ? (
          <>
            <BrokerStatus style={{ margin: 0 }}>
              <Skeleton type="button" />
              <Skeleton type="button" />
            </BrokerStatus>
          </>
        ) : (
          <BrokerStatus>
            {isLoadingBrokerRegistrationStatus ? (
              <Skeleton active type="button" />
            ) : (
              <Select
                value={registrationStatus}
                options={translatedOptions()}
                onChange={(value) => {
                  if (typeof value === 'string') {
                    setRegistrationStatus(value)
                    fillBrokerRegistrationStatus({ registrationStatus: value })
                  }
                }}
              />
            )}
            <IsRegisteredInInternalSystemDiv>
              {isLoadingInternalSystemId ? (
                <Skeleton active type="button" />
              ) : (
                <IsRegisteredInInternalSystem>
                  Cód Sisweb:
                  <InternalSystemIdTextInput
                    type="text"
                    value={internalSystemId}
                    ref={(input) => input && input.focus()}
                    onChange={(e) => setInternalSystemId(e.target.value)}
                    disabled={isEditable}
                  />
                  <EditableButton onClick={() => setIsEditable(!isEditable)}>
                    {isEditable ? (
                      <EditOutlined />
                    ) : (
                      <CheckOutlined
                        onClick={() =>
                          fillBrokerInternalSystemId({ internalSystemId: internalSystemId })
                        }
                      />
                    )}
                  </EditableButton>
                </IsRegisteredInInternalSystem>
              )}
            </IsRegisteredInInternalSystemDiv>
          </BrokerStatus>
        )}
      </Header>

      <ModalBody>
        {isLoading ? (
          <SkeletonWrapper>
            <SkeletonCard />
          </SkeletonWrapper>
        ) : (
          <>
            <PersonalInformation data={brokersData} />
            <PaymentInformation data={brokersData} />
            <RegisteredOperators data={brokersData} />
          </>
        )}
      </ModalBody>
    </>
  )
}

export default BrokerModalContent
