export const modalitiesGndi = [
  { label: 'Nenhum', value: 'Nenhum' },
  { label: 'Ambulatorial', value: 'Ambulatorial' },
  { label: 'Ambulatorial + Hospitalar', value: 'Ambulatorial + Hospitalar' },
  {
    label: 'Ambulatorial + Hospitalar + Obstetrícia',
    value: 'Ambulatorial + Hospitalar + Obstetrícia',
  },
  { label: 'Ambulatorial + Hospitalar + Odonto', value: 'Ambulatorial + Hospitalar + Odonto' },
  {
    label: 'Ambulatorial + Hospitalar + Obstetrícia + Odonto',
    value: 'Ambulatorial + Hospitalar + Obstetrícia + Odonto',
  },
  { label: 'Hospitalar', value: 'Hospitalar' },
  { label: 'Hospitalar + Obstetricia', value: 'Hospitalar + Obstetricia' },
  { label: 'Hospitalar + Odonto', value: 'Hospitalar + Odonto' },
  { label: 'Hospitalar + Obstetricia + odonto', value: 'Hospitalar + Obstetricia + odonto' },
  { label: 'Ambulatorial + Odonto', value: 'Ambulatorial + Odonto' },
  { label: 'Referência', value: 'Referência' },
]
