import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { transformAddressToEntityAddress } from '@/App/utils/parseAddress'

import { api } from '../../http'
import { GetOrderInformationBody } from '../dtos'

type GetOrderInformation = {
  orderId?: string | null
}

type GetOrderInformationRequest = QueryFunctionContext & GetOrderInformation

async function orderInformation({ signal, orderId }: GetOrderInformationRequest) {
  const response = await api.get<GetOrderInformationBody>(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v2/orders/${orderId}`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export function orderInformationQueryOptions({
  orderId,
  enabled = true,
}: GetOrderInformation & { enabled?: boolean }) {
  return queryOptions({
    queryKey: ['order', orderId] as const,
    queryFn: async (params) => orderInformation({ ...params, orderId }),
    refetchOnWindowFocus: false,
    enabled: !!orderId && enabled,
    select(data) {
      if (data.company) {
        data.company = Object.assign(
          data.company,
          transformAddressToEntityAddress(data.company.address),
        )
      }

      if (data.holders && data.holders.length > 0) {
        data.holders = data.holders.map((holder) => {
          if (holder.dependents) {
            holder.dependents = holder.dependents.map((dependent) =>
              Object.assign(dependent, transformAddressToEntityAddress(dependent.address), {
                hiredHealthPlan: dependent.hiredPlans?.find((plan) => plan.type === 'HEALTH'),
                hiredDentalPlan: dependent.hiredPlans?.find((plan) => plan.type === 'DENTAL'),
                dentalPlan: dependent.quotationPlans?.find((plan) => plan.type === 'DENTAL'),
                healthPlan: dependent.quotationPlans?.find((plan) => plan.type === 'HEALTH'),
              }),
            )
          }

          return Object.assign(holder, transformAddressToEntityAddress(holder.address), {
            hiredHealthPlan: holder.hiredPlans?.find((plan) => plan.type === 'HEALTH'),
            hiredDentalPlan: holder.hiredPlans?.find((plan) => plan.type === 'DENTAL'),
            dentalPlan: holder.quotationPlans?.find((plan) => plan.type === 'DENTAL'),
            healthPlan: holder.quotationPlans?.find((plan) => plan.type === 'HEALTH'),
            financialOfficer: data.financialOfficer,
          })
        })
      }

      return {
        ...data,
        isRegisteredInInsurer: !!data?.proposalIssued,
      }
    },
  })
}

export const useOrderInformationQuery = ({
  orderId,
  enabled = true,
}: GetOrderInformation & { enabled?: boolean }) => {
  return useQuery(orderInformationQueryOptions({ orderId, enabled }))
}
