import React from 'react'

import { StoreApi, createStore, useStore } from 'zustand'

import { CreateOrderInputDto, CreateQuotationInputDto } from '@/App/clients/healthPlansOrders/dtos'

type CreateOrderContextState = {
  broker?: {
    id?: string | null
    name?: string | null
  } | null
  lead?: {
    id?: string | null
    name?: string | null
  } | null
  quotation: {
    data?: CreateQuotationInputDto | null
    isLoading?: boolean | null
    error?: string | null
  }
  order: {
    data?: (CreateOrderInputDto & { registeredInsurerId?: string }) | null
    isLoading?: boolean | null
    error?: string | null
  }
  isRegisteredInInsurer: boolean
}

type CreateOrderContextAction = {
  setOrder: (order: Partial<CreateOrderContextState['order']>) => void
  setQuotation: (quotation: Partial<CreateOrderContextState['quotation']>) => void
  setBroker: (broker: Partial<CreateOrderContextState['broker']>) => void
  setLead: (lead: Partial<CreateOrderContextState['lead']>) => void
}

type CreateOrderContextData = CreateOrderContextState & CreateOrderContextAction

const CreateOrderDefaultData: CreateOrderContextData = {
  broker: null,
  lead: null,
  isRegisteredInInsurer: false,
  order: {
    data: null,
    error: null,
    isLoading: false,
  },
  quotation: {
    data: null,
    error: null,
    isLoading: false,
  },
  setOrder: () => null,
  setQuotation: () => null,
  setBroker: () => null,
  setLead: () => null,
}

const CreateOrderContext = React.createContext<StoreApi<CreateOrderContextData> | null>(null)

export const CreateOrderProvider = ({ children, initialData = CreateOrderDefaultData }) => {
  const [store] = React.useState(() =>
    createStore<CreateOrderContextData>((set) => ({
      ...initialData,
      setBroker(broker) {
        set((state) => {
          if (!broker) {
            return {
              broker: null,
            }
          }

          return {
            broker: {
              ...state.broker,
              ...broker,
            },
          }
        })
      },
      setLead(lead) {
        set((state) => {
          if (!lead) {
            return {
              lead: null,
            }
          }

          return {
            lead: {
              ...state.lead,
              ...lead,
            },
          }
        })
      },
      setOrder({ data, error, isLoading }) {
        set((state) => {
          return {
            order: {
              error: error || state.order.error,
              isLoading: isLoading || state.order.isLoading,
              data: {
                ...state.order.data,
                ...data,
              },
            },
          }
        })
      },
      setQuotation({ data, error, isLoading }) {
        set((state) => ({
          quotation: {
            error: error || state.quotation.error,
            isLoading: isLoading || state.quotation.isLoading,
            data: {
              ...state.quotation.data,
              ...data,
            },
          },
        }))
      },
    })),
  )

  return <CreateOrderContext.Provider value={store}>{children}</CreateOrderContext.Provider>
}

export function useCreateOrderContextStore<T>(selector: (state: CreateOrderContextData) => T): T {
  const store = React.useContext(CreateOrderContext)

  if (!store) {
    throw new Error('CreateOrderStepContext not provided in the provider tree.')
  }

  return useStore(store, selector)
}

export const useCreateOrderContext = () =>
  useCreateOrderContextStore((state: CreateOrderContextData) => state)
