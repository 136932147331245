import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { api } from '../../http'
import { GetCatalogPlanDto } from '../dtos'

type GetCatalogPlanBaseRequest = {
  insurerId?: string | null
  modalityId?: string | null
}

type GetCatalogPlanRequest = QueryFunctionContext & GetCatalogPlanBaseRequest

async function getCatalogPlan({ insurerId, modalityId, signal }: GetCatalogPlanRequest) {
  const response = await api.get<GetCatalogPlanDto>(
    `${import.meta.env.VITE_CATALOGO_API_URL}/operadoras/${insurerId}/planos`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
      params: { ...(modalityId ? { modalidadeId: modalityId } : {}), situacaoPlano: 'A' },
    },
  )
  return response.data
}

export function getCatalogPlanOptions({
  insurerId,
  modalityId,
  enabled = false,
}: GetCatalogPlanBaseRequest & { enabled?: boolean }) {
  return queryOptions({
    queryKey: ['catalog', 'insurers', insurerId, 'plans', { modalityId }] as const,
    queryFn: async (params) => getCatalogPlan({ ...params, insurerId, modalityId }),
    refetchOnWindowFocus: false,
    enabled: !!insurerId && enabled,
    select(data) {
      return data.data
        .map((plan) => ({
          label: plan.nome,
          value: plan.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
  })
}

export const useCatalogPlansQuery = ({
  insurerId,
  modalityId,
  enabled = false,
}: GetCatalogPlanBaseRequest & { enabled?: boolean }) => {
  return useQuery(getCatalogPlanOptions({ insurerId, modalityId, enabled }))
}
