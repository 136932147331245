import { useMutation } from '@tanstack/react-query'

import { api } from '@/App/clients/http'

type UpdateDocs = {
  internalId: string
  modality: string
  insurerOriginId?: string
  insurerDestinationId: string
}

async function updateOrderDocs({
  internalId,
  modality,
  insurerOriginId,
  insurerDestinationId,
}: UpdateDocs) {
  const response = await api.post(
    `${import.meta.env.VITE_BLISS_ORDER_JOB_API_URL}/order/update/doc-types`,
    {
      internalId,
      modality,
      insurerOriginId,
      insurerDestinationId,
    },
    {
      headers: {
        'x-api-key': import.meta.env.VITE_BLISS_ORDER_JOB_API_KEY,
        'Content-Type': 'application/json',
      },
    },
  )
  return response.data
}

export const useUpdateOrderDocs = () => {
  return useMutation({
    mutationKey: ['updateOrderDocs'],
    mutationFn: updateOrderDocs,
  })
}
