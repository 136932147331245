import { Control, UseFormRegister } from 'react-hook-form'

import RadioGroup from 'design-system/Radio/Radio'
import { Select, TextInput } from 'design-system/components'

import { FillQuotationInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import { FormItem, FormItemLabel, FormItemRadio } from '../style'

interface SulamericaFormQuotationProps {
  formValues: FillQuotationInformationBody
  control: Control<FillQuotationInformationBody>
  register: UseFormRegister<FillQuotationInformationBody>
  hiringType: string | undefined
}

export const SulamericaFormQuotation: React.FC<SulamericaFormQuotationProps> = ({
  control,
  register,
  formValues,
  hiringType,
}) => {
  return (
    <>
      <FormItem>
        <TextInput
          name="externalId"
          label="Número da cotação*"
          placeholder="0000000"
          vertical
          register={{
            ...register('externalId'),
          }}
        />
      </FormItem>
      <FormItemLabel>Coparticipação*</FormItemLabel>
      <FormItemRadio>
        <RadioGroup
          name="isCoparticipation"
          options={[
            { value: 'true', label: 'Completa' },
            { value: 'false', label: 'Parcial' },
          ]}
          defaultValue={formValues?.isCoparticipation ? 'true' : 'false'}
          register={{
            ...register('isCoparticipation'),
          }}
        />
      </FormItemRadio>

      <FormItem>
        <FormItemLabel>Tipo de contratação*</FormItemLabel>
        <Select
          showSearch
          defaultValue={hiringType}
          name="hiringType"
          control={control}
          options={[
            { label: 'CBO', value: 'CBO' },
            { label: 'Compulsório', value: 'Compulsório' },
            { label: 'Flex', value: 'Flex' },
          ]}
          placeholder="Selecione o tipo de contratação"
        />
      </FormItem>

      <FormItem>
        <FormItemLabel>Tipo de plano*</FormItemLabel>
        <Select
          showSearch
          name="coverageType"
          control={control}
          options={[
            {
              label: 'Ambulatorial e Hospitalar com Obstetrícia',
              value: 'Ambulatorial e Hospitalar com Obstetrícia',
            },
            { label: 'Hospitalar com Obstetrícia', value: 'Hospitalar com Obstetrícia' },
          ]}
          placeholder="Selecione o tipo de plano"
        />
      </FormItem>

      <FormItem>
        <FormItemLabel>Vigência contratual*</FormItemLabel>
        <Select
          showSearch
          name="contractualTerm"
          control={control}
          options={[
            { label: '12 meses', value: '12 meses' },
            { label: '24 meses', value: '24 meses' },
          ]}
          placeholder="Selecione o período"
        />
      </FormItem>
    </>
  )
}
