import React from 'react'
import { Control, Controller, ControllerRenderProps, RegisterOptions } from 'react-hook-form'

import { DatePicker as AntdDatePicker } from 'antd'
import dayjs from 'dayjs'

import Error from '../Error/Error'
import { StyledDatePickerWrapper, StyledLabel } from './style'

dayjs.locale('pt-br')

export interface Props {
  placeholder?: string
  onChange?: (date: dayjs.Dayjs, dateString: string | string[]) => void
  size?: 'large' | 'middle' | 'small'
  label?: string
  disabled?: boolean
  disabledDate?: (date: dayjs.Dayjs) => boolean
  name: string
  control?: Control<any>
  rules?: RegisterOptions
  outputFormat?: string
  defaultValue?: Date | null
  value?: Date | null
}

const DatePicker: React.FC<Props> = ({
  control,
  name,
  size,
  label,
  disabled,
  placeholder = 'Selecione uma data',
  disabledDate,
  onChange,
  rules,
  outputFormat = 'YYYY-MM-DD',
  defaultValue,
  value,
}) => {
  const handleDatePickerChange = (field: ControllerRenderProps<any>, date: dayjs.Dayjs | null) => {
    if (!date) return field.onChange(null)

    field.onChange(date ? date.format(outputFormat) : null)
  }

  const dateFormatList = ['DD/MM/YYYY', 'DDMMYYYY']

  return (
    <>
      {control ? (
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => {
            return (
              <>
                <StyledLabel>{label}</StyledLabel>
                <StyledDatePickerWrapper>
                  <AntdDatePicker
                    disabledDate={disabledDate}
                    placeholder={placeholder}
                    status={fieldState.error ? 'error' : undefined}
                    ref={field.ref}
                    name={field.name}
                    onBlur={field.onBlur}
                    format={dateFormatList}
                    value={field.value ? dayjs(field.value) : null}
                    disabled={disabled}
                    size={size}
                    onChange={(date, dateString) => {
                      if (onChange) return onChange(date, dateString)
                      else {
                        return handleDatePickerChange(field, date)
                      }
                    }}
                  />
                </StyledDatePickerWrapper>
                {fieldState.error && (
                  <>
                    <Error error={fieldState.error}></Error>
                  </>
                )}
              </>
            )
          }}
        />
      ) : (
        <>
          <StyledLabel>{label}</StyledLabel>
          <StyledDatePickerWrapper>
            <AntdDatePicker
              placeholder={placeholder}
              onChange={(date, dateString) => onChange && onChange(date, dateString)}
              format={dateFormatList}
              size={size}
              disabled={disabled}
              value={value ? dayjs(value) : null}
            />
          </StyledDatePickerWrapper>
        </>
      )}
    </>
  )
}

export default DatePicker
