import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { OnFileUploadInput } from 'design-system/Upload/Upload'
import { triggerToast } from 'design-system/triggers'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import {
  CreateOrderInputDto,
  CreateOrderOutputDto,
  QuotationDto,
} from '@/App/clients/healthPlansOrders/dtos'
import useFetch, { useFetchParallel } from '@/App/clients/http'
import type { UseFetchConfig } from '@/App/clients/http/interface'
import type { Maybe } from '@/App/helpers/maybe'

import { UserContext } from '../../../contexts/userData/userContext'
import Quotation from './quotation'
import { useCreateOrderContext } from './store'
import { OrderDiv } from './style'

export interface CreateOrderProps {
  createLeadIsLoading: boolean
}

const Order: React.FC<CreateOrderProps> = ({ createLeadIsLoading }) => {
  const [createdQuotation, setCreatedQuotation] = useState<QuotationDto>()
  const [selectedQuotation, setSelectedQuotation] = useState<QuotationDto>()
  const [proposalMirrorFile, setProposalMirrorFile] = useState<Maybe<OnFileUploadInput>>()
  const [quotationAttachmentFile, setQuotationAttachmentFile] = useState<Maybe<OnFileUploadInput>>()
  const navigate = useNavigate()

  const userContext = useContext(UserContext)
  const createOrderContext = useCreateOrderContext()

  const {
    setRequestConfig: setCreateOrderRequestConfig,
    data: createOrderData,
    statusCode: createOrderStatusCode,
    isLoading: createOrderIsLoading,
    error: createOrderError,
  } = useFetch<CreateOrderOutputDto>()

  const {
    setRequestConfig: setUploadFilesByUploadURLRequestConfig,
    statusCode: uploadFilesByUploadURLStatusCodes,
    isLoading: isLoadingUploadFilesByUploadURL,
  } = useFetchParallel()

  useEffect(() => {
    if (createdQuotation != null) {
      createOrder({
        ...createdQuotation,
        brokerId: createOrderContext.broker?.id,
        origin: 'INTRANET',
        quotationAttachmentFile: null,
        quotationAttachmentFileName: null,
      })
    }
  }, [createdQuotation])

  useEffect(() => {
    if (createOrderStatusCode === 201) {
      if (proposalMirrorFile || quotationAttachmentFile) {
        const requestConfigs: Array<UseFetchConfig> = []
        const proposalContentType = proposalMirrorFile?.fileContentType
        if (createOrderData?.proposalMirrorUploadURL && proposalContentType) {
          requestConfigs.push(
            HealthPlansOrdersRequests.uploadOrderDocumentByUploadURL(
              createOrderData.proposalMirrorUploadURL,
              proposalMirrorFile.fileContent,
              proposalContentType,
            ),
          )
        }

        const quotationAttachmentContentFile = quotationAttachmentFile?.fileContentType
        if (quotationAttachmentContentFile && createOrderData?.quotationAttachmentUploadURL) {
          requestConfigs.push(
            HealthPlansOrdersRequests.uploadOrderDocumentByUploadURL(
              createOrderData.quotationAttachmentUploadURL,
              quotationAttachmentFile.fileContent,
              quotationAttachmentContentFile,
            ),
          )
        }

        setUploadFilesByUploadURLRequestConfig(requestConfigs)
      } else {
        navigate(`/propostas/${createOrderData?.id}`)
      }
    }
  }, [createOrderStatusCode])

  useEffect(() => {
    if (!uploadFilesByUploadURLStatusCodes) return
    const [uploadFilesStatusCode] = uploadFilesByUploadURLStatusCodes

    if (uploadFilesStatusCode === 200) {
      navigate(`/propostas/${createOrderData?.id}`)
    } else if (uploadFilesStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao criar a proposta')
  }, [uploadFilesByUploadURLStatusCodes])

  useEffect(() => {
    if (createOrderError?.message) {
      createOrderContext.setOrder({
        error: createOrderError.message,
      })
    }
  }, [createOrderError])

  useEffect(() => {
    createOrderContext.setOrder({
      isLoading: createOrderIsLoading || isLoadingUploadFilesByUploadURL,
    })
  }, [createOrderIsLoading, isLoadingUploadFilesByUploadURL])

  const createOrder = (input: CreateOrderInputDto) => {
    const order = { ...input, ...selectedQuotation, ...createOrderContext.order?.data }

    const formData = new FormData()

    Object.keys({
      ...order,
      proposalMirrorFile: null,
      quotationAttachmentFile: null,
    }).forEach((key) => {
      if (order[key] != null) {
        switch (key) {
          case 'quotationAttachmentFile':
            if (!order.quotationAttachmentFile?.fileName) {
              break
            }

            formData.append('quotationAttachmentFileName', order.quotationAttachmentFile.fileName)
            setQuotationAttachmentFile(order[key])
            break
          case 'proposalMirrorFile':
            if (!order.proposalMirrorFile?.fileName) {
              break
            }

            formData.append('proposalMirrorFileName', order.proposalMirrorFile.fileName)
            setProposalMirrorFile(order[key])
            break

          default:
            formData.append(key, order[key])
            break
        }
      }
    })

    if (createOrderContext.broker?.id) {
      formData.append('brokerId', createOrderContext.broker?.id)
    }
    formData.append('origin', 'INTRANET')
    if (userContext?.user?.id) {
      formData.append('responsibleAdminId', userContext.user.id)
    }
    formData.append('insurerId', formData.get('registeredInsurerId') || '')

    if (createOrderContext.order.data?.proposalIssued && createOrderContext.lead?.id) {
      formData.append('leadId', createOrderContext.lead?.id)
    }
    if (selectedQuotation && selectedQuotation.id) {
      formData.append('quotationId', selectedQuotation.id)
    }
    if (createdQuotation && createdQuotation.id) {
      formData.append('quotationId', createdQuotation.id)
    }

    const createLeadRequestConfig = HealthPlansOrdersRequests.createOrder(formData)
    setCreateOrderRequestConfig(createLeadRequestConfig)
  }

  return (
    <OrderDiv>
      <Quotation
        createLeadIsLoading={createLeadIsLoading}
        setCreatedQuotation={setCreatedQuotation}
        createOrder={createOrder}
        setSelectedQuotation={setSelectedQuotation}
      />
    </OrderDiv>
  )
}

export default Order
