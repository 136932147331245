export const insurersGndi = [
  {
    label: 'Operadora do grupo Hapvida Notredame Intermédica',
    value: 'Operadora do grupo Hapvida Notredame Intermédica',
  },
  { label: '1-CCG', value: '1-CCG' },
  { label: '1-CLINIPAM', value: '1-CLINIPAM' },
  { label: '1-GNDI MINAS', value: '1-GNDI MINAS' },
  { label: '1-HAPVIDA', value: '1-HAPVIDA' },
  { label: '1-NOTRE DAME', value: '1-NOTRE DAME' },
  { label: '100-ASSIM - GRUPO HOSPIT. RJ', value: '100-ASSIM - GRUPO HOSPIT. RJ' },
  { label: '101-SIM SAUDE', value: '101-SIM SAUDE' },
  { label: '102-ITALICA SAUDE INTERNACIONAL', value: '102-ITALICA SAUDE INTERNACIONAL' },
  { label: '103-VIDA PLENA', value: '103-VIDA PLENA' },
  { label: '104-ADMED REG. 32605-4', value: '104-ADMED REG. 32605-4' },
  { label: '105-MEDSAUDE REG. 38.096', value: '105-MEDSAUDE REG. 38.096' },
  {
    label: '106-JARDINS ASSIT. MEDICA LTDA ANS 316920',
    value: '106-JARDINS ASSIT. MEDICA LTDA ANS 316920',
  },
  { label: '107-DIX SAUDE ANS 362921', value: '107-DIX SAUDE ANS 362921' },
  { label: '108-SANTA CASA DE SANTOS SUSEP 31649', value: '108-SANTA CASA DE SANTOS SUSEP 31649' },
  {
    label: '109-LIFE SYSTEM ASSISTENCIA MEDICA ANS307408',
    value: '109-LIFE SYSTEM ASSISTENCIA MEDICA ANS307408',
  },
  { label: '11-SAUDE BRADESCO', value: '11-SAUDE BRADESCO' },
  { label: '110-INTERMEDICA', value: '110-INTERMEDICA' },
  { label: '111-CAPESP SAUDE ANS 3457', value: '111-CAPESP SAUDE ANS 3457' },
  {
    label: '112-SANTA CASA DE BELO HORIZONTE ANS 32525',
    value: '112-SANTA CASA DE BELO HORIZONTE ANS 32525',
  },
  { label: '113-MASTER SAUDE ANS 370967', value: '113-MASTER SAUDE ANS 370967' },
  { label: '114-NIPOMED', value: '114-NIPOMED' },
  { label: '115-CRUSAM ANS 324698', value: '115-CRUSAM ANS 324698' },
  { label: '116-SAO LUCAS MACEIO', value: '116-SAO LUCAS MACEIO' },
  { label: '117-PAME ANS 342408', value: '117-PAME ANS 342408' },
  { label: '118-AMICO ANS', value: '118-AMICO ANS' },
  { label: '119-AVIMED 766979', value: '119-AVIMED 766979' },
  { label: '12-SAUDE BAMERINDUS', value: '12-SAUDE BAMERINDUS' },
  { label: '120-PROMEDICA  ANS 230862', value: '120-PROMEDICA  ANS 230862' },
  {
    label: '121-POLICLINICA SAO JOSE ANS41938-5 MACEIO',
    value: '121-POLICLINICA SAO JOSE ANS41938-5 MACEIO',
  },
  { label: '122-NIPON SAUDE ANS 41451-4', value: '122-NIPON SAUDE ANS 41451-4' },
  { label: '123-EVANGELICOS', value: '123-EVANGELICOS' },
  { label: '124-UNIVERSITARIOS', value: '124-UNIVERSITARIOS' },
  { label: '125-CRA - ADMINISTRADORES', value: '125-CRA - ADMINISTRADORES' },
  { label: '126-STARMED REG 003121', value: '126-STARMED REG 003121' },
  { label: '127-SERVITAL ANS 30466-2', value: '127-SERVITAL ANS 30466-2' },
  { label: '128-NORCLINICAS', value: '128-NORCLINICAS' },
  { label: '129-SERMA ANS 388122', value: '129-SERMA ANS 388122' },
  { label: '13-SUL AMERICA', value: '13-SUL AMERICA' },
  { label: '130-SAO FRANCISCO BELEM ANS 403962', value: '130-SAO FRANCISCO BELEM ANS 403962' },
  { label: '131-CEP SAUDE ANS 402524', value: '131-CEP SAUDE ANS 402524' },
  { label: '132-TOP SAUDE ANS 348392', value: '132-TOP SAUDE ANS 348392' },
  { label: '133-CAURN NATAL', value: '133-CAURN NATAL' },
  { label: '134-SO SAUDE', value: '134-SO SAUDE' },
  { label: '135-MED MAIS ANS 411264', value: '135-MED MAIS ANS 411264' },
  { label: '136-SAMEL ANS 411116', value: '136-SAMEL ANS 411116' },
  { label: '137-LUCK SAUDE ANS 305791 BELEM PA', value: '137-LUCK SAUDE ANS 305791 BELEM PA' },
  {
    label: '138-CONSULT-ASSIST.MEDICA S.PAULO 304662',
    value: '138-CONSULT-ASSIST.MEDICA S.PAULO 304662',
  },
  { label: '139-PAS ANS', value: '139-PAS ANS' },
  { label: '14-UNIBANCO SAUDE', value: '14-UNIBANCO SAUDE' },
  { label: '140-RIO MED  35378-7', value: '140-RIO MED  35378-7' },
  { label: '141-CCE INTERMED - ANS 416398', value: '141-CCE INTERMED - ANS 416398' },
  { label: '142-AMAZONIA SAUDE ANS 384054', value: '142-AMAZONIA SAUDE ANS 384054' },
  { label: '143-CCE UNIMED - ANS 353353', value: '143-CCE UNIMED - ANS 353353' },
  { label: '144-CANP SAUDE ANS 344877', value: '144-CANP SAUDE ANS 344877' },
  {
    label: '145-HOSPITAL LUIS DE FRANÇA ANS 40775-5',
    value: '145-HOSPITAL LUIS DE FRANÇA ANS 40775-5',
  },
  { label: '146-AMESP ANS 32710-7', value: '146-AMESP ANS 32710-7' },
  { label: '148-FAMED  ANS 414689', value: '148-FAMED  ANS 414689' },
  { label: '149-SAME ANS 332831', value: '149-SAME ANS 332831' },
  { label: '150-AMS ANS 36687-1', value: '150-AMS ANS 36687-1' },
  { label: '151-CCE IDEAL SAUDE - ANS 420751', value: '151-CCE IDEAL SAUDE - ANS 420751' },
  { label: '16-PETROBRAS', value: '16-PETROBRAS' },
  { label: '17-BANCO CENTRAL', value: '17-BANCO CENTRAL' },
  { label: '174-AME SAUDE MACEIO', value: '174-AME SAUDE MACEIO' },
  { label: '175-ATENDE SAUDE', value: '175-ATENDE SAUDE' },
  { label: '176-CONMEDH', value: '176-CONMEDH' },
  { label: '177-SAUDE EXCELSIOR', value: '177-SAUDE EXCELSIOR' },
  { label: '178-REAL', value: '178-REAL' },
  { label: '179-MMS', value: '179-MMS' },
  { label: '182-COMPRA CARTEIRA SANTA SAUDE', value: '182-COMPRA CARTEIRA SANTA SAUDE' },
  { label: '183-COMPRA CARTEIRA MMS', value: '183-COMPRA CARTEIRA MMS' },
  { label: '184-COMPRA CARTEIRA SANTA HELENA', value: '184-COMPRA CARTEIRA SANTA HELENA' },
  { label: '185-COMPRA CARTEIRA SANTA CLARA', value: '185-COMPRA CARTEIRA SANTA CLARA' },
  { label: '186-COMPRA CARTEIRA DENTAL VIDA', value: '186-COMPRA CARTEIRA DENTAL VIDA' },
  { label: '187-COMPRA CARTEIRA SAO LUCAS', value: '187-COMPRA CARTEIRA SAO LUCAS' },
  { label: '188-AMERICA SAUDE', value: '188-AMERICA SAUDE' },
  { label: '189-RECIFE MERIDIONAL', value: '189-RECIFE MERIDIONAL' },
  { label: '190-PLANSFER', value: '190-PLANSFER' },
  { label: '191-ASL  ASSISTÊNCIA À SAÚDE', value: '191-ASL  ASSISTÊNCIA À SAÚDE' },
  { label: '192-NOTRE DAME SEGUROS', value: '192-NOTRE DAME SEGUROS' },
  { label: '193-UNILIFE', value: '193-UNILIFE' },
  { label: '194-MED VIDA SAUDE', value: '194-MED VIDA SAUDE' },
  {
    label: '196-UNIAO MEDICA - ANS 41458-1 (F.SANTANA)',
    value: '196-UNIAO MEDICA - ANS 41458-1 (F.SANTANA)',
  },
  { label: '197-CAMP. CANC - BONF 90 DIAS', value: '197-CAMP. CANC - BONF 90 DIAS' },
  { label: '198-COMPRA CARTEIRA UNIPLAM-PI', value: '198-COMPRA CARTEIRA UNIPLAM-PI' },
  { label: '199-COMPRA CARTEIRA  FREE LIFE- CE', value: '199-COMPRA CARTEIRA  FREE LIFE- CE' },
  { label: '2-PREVINIR NÃO REG. ORDEM CONSELHO', value: '2-PREVINIR NÃO REG. ORDEM CONSELHO' },
  { label: '200-LIFEDAY - ANS 30969-9', value: '200-LIFEDAY - ANS 30969-9' },
  { label: '201-AGEMED - ANS 33960-1', value: '201-AGEMED - ANS 33960-1' },
  { label: '202-RED. DE CARENCIA - CRP 2019.2', value: '202-RED. DE CARENCIA - CRP 2019.2' },
  { label: '203-CCE JOINVILLE 2019.2', value: '203-CCE JOINVILLE 2019.2' },
  { label: '204-CCE NO/NE 2019.1 (UNIMED)', value: '204-CCE NO/NE 2019.1 (UNIMED)' },
  { label: '205-SAO CAMILO - (JUAZEIRO)', value: '205-SAO CAMILO - (JUAZEIRO)' },
  { label: '206-CCE 2019.4 - UNIMED MANAUS', value: '206-CCE 2019.4 - UNIMED MANAUS' },
  { label: '207-CCE 2019.5 - UNIMED NATAL/AMIL', value: '207-CCE 2019.5 - UNIMED NATAL/AMIL' },
  { label: '208-UNIMED NO/NE - REG ANS 32421-3', value: '208-UNIMED NO/NE - REG ANS 32421-3' },
  { label: '209-UNIMED NATAL - REG ANS 33559-2', value: '209-UNIMED NATAL - REG ANS 33559-2' },
  { label: '21-CAFAZ', value: '21-CAFAZ' },
  { label: '210-AMIL (CCE 2020.2 - NATAL)', value: '210-AMIL (CCE 2020.2 - NATAL)' },
  { label: '211-CANCELADA - CCE 2020.3 - AMIL', value: '211-CANCELADA - CCE 2020.3 - AMIL' },
  { label: '212-CRP 2020.1 (CAMP. DE CANC)', value: '212-CRP 2020.1 (CAMP. DE CANC)' },
  { label: '213-LIV SAUDE - REG ANS 37928-0', value: '213-LIV SAUDE - REG ANS 37928-0' },
  { label: '214-HUMANA SAUDE - REG ANS 37751-1', value: '214-HUMANA SAUDE - REG ANS 37751-1' },
  { label: '215-CCE 2020.4 - AMIL', value: '215-CCE 2020.4 - AMIL' },
  { label: '216-AMERICA - MIGRACAO', value: '216-AMERICA - MIGRACAO' },
  { label: '217-PROMED - MIGRACAO', value: '217-PROMED - MIGRACAO' },
  { label: '218-AME - MIGRACAO', value: '218-AME - MIGRACAO' },
  { label: '219-RN - MIGRACAO', value: '219-RN - MIGRACAO' },
  { label: '220-CASSI - ANS 346659', value: '220-CASSI - ANS 346659' },
  { label: '221-GEAP - REG. ANS 323080', value: '221-GEAP - REG. ANS 323080' },
  { label: '222-CAMP. DE CANCELADOS', value: '222-CAMP. DE CANCELADOS' },
  { label: '223-SAMEDH - MIGRACAO', value: '223-SAMEDH - MIGRACAO' },
  { label: '224-PLAMHEG - MIGRACAO', value: '224-PLAMHEG - MIGRACAO' },
  { label: '225-CLINIPAM - RG ANS 34078-2', value: '225-CLINIPAM - RG ANS 34078-2' },
  { label: '226-GSF - SAUDE - MIGRACAO', value: '226-GSF - SAUDE - MIGRACAO' },
  { label: '227-GSF - ODONTO - MIGRACAO', value: '227-GSF - ODONTO - MIGRACAO' },
  { label: '228-CCE 2021.5 - GRUPO PATRIA', value: '228-CCE 2021.5 - GRUPO PATRIA' },
  { label: '229-CAMP. DE RED DE CARENCIA', value: '229-CAMP. DE RED DE CARENCIA' },
  { label: '230-SAMEL - REG ANS 367095', value: '230-SAMEL - REG ANS 367095' },
  { label: '231-SÃO JOSÉ SAÚDE - MIGRACAO', value: '231-SÃO JOSÉ SAÚDE - MIGRACAO' },
  { label: '232-MEDICAL - MIGRACAO', value: '232-MEDICAL - MIGRACAO' },
  {
    label: '233-STA CASA SAO J. DOS CAMPOS - ANS 419249',
    value: '233-STA CASA SAO J. DOS CAMPOS - ANS 419249',
  },
  { label: '234-POLICLIN SAUDE S/A - ANS 415693', value: '234-POLICLIN SAUDE S/A - ANS 415693' },
  { label: '235-PREMIUM - MIGRACAO', value: '235-PREMIUM - MIGRACAO' },
  { label: '236-MEDSERVICE', value: '236-MEDSERVICE' },
  { label: '237-PMMG IPSM', value: '237-PMMG IPSM' },
  { label: '238-PRONTOMED', value: '238-PRONTOMED' },
  { label: '239-VITALLIS', value: '239-VITALLIS' },
  { label: '262-SAUDE SISTEMAS - MIGRACAO', value: '262-SAUDE SISTEMAS - MIGRACAO' },
  { label: '263-PROMED BR -  MIGRACAO', value: '263-PROMED BR -  MIGRACAO' },
  { label: '264-PROMED BH - MIGRACAO', value: '264-PROMED BH - MIGRACAO' },
  { label: '265-SAUDE SIM - ANS 320111', value: '265-SAUDE SIM - ANS 320111' },
  { label: '266-IDEAL SAUDE - ANS 420751', value: '266-IDEAL SAUDE - ANS 420751' },
  { label: '267-QUALLITY - ANS 418170', value: '267-QUALLITY - ANS 418170' },
  { label: '268-MEDSENIOR - ANS 335614', value: '268-MEDSENIOR - ANS 335614' },
  { label: '269-RN METROPOLITAN - MIGRACAO', value: '269-RN METROPOLITAN - MIGRACAO' },
  { label: '270-UNIHOSP - ANS 412538', value: '270-UNIHOSP - ANS 412538' },
  { label: '271-SEMPRE VIDA - ANS 392391', value: '271-SEMPRE VIDA - ANS 392391' },
  { label: '272-SAUDE FATIMA - ANS 369373', value: '272-SAUDE FATIMA - ANS 369373' },
  {
    label: '273-ATIVIA SERVIÇOS DE SAUDE - ANS 320510',
    value: '273-ATIVIA SERVIÇOS DE SAUDE - ANS 320510',
  },
  { label: '274-S1 OPERADORA - ANS 420981', value: '274-S1 OPERADORA - ANS 420981' },
  { label: '275-SLAM - ANS 358509', value: '275-SLAM - ANS 358509' },
  { label: '276-SMILE - ANS 395480', value: '276-SMILE - ANS 395480' },
  { label: '277-AASMED', value: '277-AASMED' },
  { label: '278-ABERTTA SAUDE', value: '278-ABERTTA SAUDE' },
  { label: '279-CASU UNIVERSIDADE', value: '279-CASU UNIVERSIDADE' },
  { label: '28-BLUE LIFE', value: '28-BLUE LIFE' },
  { label: '280-CEMIG SAUDE', value: '280-CEMIG SAUDE' },
  { label: '281-COPASS AS AST COPASA', value: '281-COPASS AS AST COPASA' },
  { label: '282-FUNDACAO LIBERTTAS MG', value: '282-FUNDACAO LIBERTTAS MG' },
  { label: '283-MEDISANITAS BRASIL A', value: '283-MEDISANITAS BRASIL A' },
  { label: '284-MEDISERVICE', value: '284-MEDISERVICE' },
  { label: '285-OAB CX ASSIST ADV MG', value: '285-OAB CX ASSIST ADV MG' },
  { label: '286-PLAN ASS REPUBLICA', value: '286-PLAN ASS REPUBLICA' },
  { label: '287-PLAN ASSIST TRABALHO', value: '287-PLAN ASSIST TRABALHO' },
  { label: '288-UNIPARVALE - CVRD', value: '288-UNIPARVALE - CVRD' },
  { label: '289-AMMP', value: '289-AMMP' },
  { label: '290-CASU UNIVERSIDADE', value: '290-CASU UNIVERSIDADE' },
  { label: '291-CNEN CN ENRG NUCLEAR', value: '291-CNEN CN ENRG NUCLEAR' },
  { label: '292-COPASS AS AST COPASA', value: '292-COPASS AS AST COPASA' },
  { label: '293-FUNDACAO FIAT', value: '293-FUNDACAO FIAT' },
  { label: '294-FUNDACAO ITAU', value: '294-FUNDACAO ITAU' },
  { label: '295-FUNDACAO MG', value: '295-FUNDACAO MG' },
  { label: '296-FUNSA', value: '296-FUNSA' },
  { label: '297-IPSEMG PREV SERV MG', value: '297-IPSEMG PREV SERV MG' },
  { label: '298-SPA SAUDE', value: '298-SPA SAUDE' },
  { label: '299-USISAUDE FND SF XAVI', value: '299-USISAUDE FND SF XAVI' },
  { label: '3-GEAP 434233/00-0', value: '3-GEAP 434233/00-0' },
  { label: '30-ASSEFAZ/346926', value: '30-ASSEFAZ/346926' },
  { label: '300-VALE DO RIO DOCE', value: '300-VALE DO RIO DOCE' },
  { label: '301-YOU ASSISTENCIA', value: '301-YOU ASSISTENCIA' },
  { label: '302-CAMP. DE VOLTA PRA CASA 2022.1', value: '302-CAMP. DE VOLTA PRA CASA 2022.1' },
  { label: '303-CCG - MIGRACAO', value: '303-CCG - MIGRACAO' },
  { label: '304-CLINIPAM - MIGRACAO', value: '304-CLINIPAM - MIGRACAO' },
  { label: '305-CCE 2024 SMILE ANS 395480', value: '305-CCE 2024 SMILE ANS 395480' },
  { label: '306-CCE 2023 - UNIMED BELEM', value: '306-CCE 2023 - UNIMED BELEM' },
  { label: '307-CCE 2024 GRUPO ATHENA', value: '307-CCE 2024 GRUPO ATHENA' },
  { label: '308-CRP 2023.1', value: '308-CRP 2023.1' },
  { label: '309-PARANA CLINICAS - ANS 350141', value: '309-PARANA CLINICAS - ANS 350141' },
  { label: '31-UNIMED', value: '31-UNIMED' },
  { label: '310-NOSSA SAUDE - ANS 372609', value: '310-NOSSA SAUDE - ANS 372609' },
  {
    label: '311-ASS EVANG BENEF DE LONDRINA - ANS 326755',
    value: '311-ASS EVANG BENEF DE LONDRINA - ANS 326755',
  },
  { label: '312-HUMANA SAUDE SUL- ANS 348180', value: '312-HUMANA SAUDE SUL- ANS 348180' },
  { label: '313-PARANA CLINICAS - ANS 350141', value: '313-PARANA CLINICAS - ANS 350141' },
  { label: '314-CABERGS - ANS 307319', value: '314-CABERGS - ANS 307319' },
  {
    label: '315-CIRCULO OPERARIO CAXIENSE - ANS 310247',
    value: '315-CIRCULO OPERARIO CAXIENSE - ANS 310247',
  },
  {
    label: '316-GEAP AUTOGESTÃO EM SAÚDE - ANS 323080',
    value: '316-GEAP AUTOGESTÃO EM SAÚDE - ANS 323080',
  },
  { label: '317-DOCTOR CLIN - ANS 349682', value: '317-DOCTOR CLIN - ANS 349682' },
  { label: '318-CCE 2023 - SAMEL', value: '318-CCE 2023 - SAMEL' },
  { label: '319-CCE 2023 - CASSI', value: '319-CCE 2023 - CASSI' },
  { label: '32-AGEMED', value: '32-AGEMED' },
  { label: '320-CCE 2023 - AMIL', value: '320-CCE 2023 - AMIL' },
  { label: '321-HB SAUDE - GRUPO HAPVIDA', value: '321-HB SAUDE - GRUPO HAPVIDA' },
  { label: '322-NDI MINAS - MIGRACAO', value: '322-NDI MINAS - MIGRACAO' },
  { label: '323-CRP 2023.2', value: '323-CRP 2023.2' },
  { label: '324-CCE 2023 - YOU SAUDE', value: '324-CCE 2023 - YOU SAUDE' },
  { label: '325-CCE 2023 - UNIMED FAMA', value: '325-CCE 2023 - UNIMED FAMA' },
  { label: '326-SAO CAMILO - ANS 318299', value: '326-SAO CAMILO - ANS 318299' },
  { label: '327-CCE 2024 UNIMED BRASIL', value: '327-CCE 2024 UNIMED BRASIL' },
  { label: '328-SULMED  - ANS 33834-6', value: '328-SULMED  - ANS 33834-6' },
  { label: '329-CCE QUALLITY - ANS 418170', value: '329-CCE QUALLITY - ANS 418170' },
  { label: '33-AMIL', value: '33-AMIL' },
  { label: '330-NDI SP - MIGRACAO', value: '330-NDI SP - MIGRACAO' },
  { label: '332-VOLTA PRA CASA YOU SAUDE', value: '332-VOLTA PRA CASA YOU SAUDE' },
  { label: '333-CCE 2024 YOU SAUDE', value: '333-CCE 2024 YOU SAUDE' },
  { label: '334-CRP 2024', value: '334-CRP 2024' },
  { label: '335-CCE 2024 CEAM', value: '335-CCE 2024 CEAM' },
  { label: '336-CCE 2024 - AMIL', value: '336-CCE 2024 - AMIL' },
  { label: '337-CCE 2024 QUALLITY ANS 418170', value: '337-CCE 2024 QUALLITY ANS 418170' },
  { label: '338-CCE 2024 IDEAL SAUDE - 420751', value: '338-CCE 2024 IDEAL SAUDE - 420751' },
  { label: '339-CCE 2024 INTERMED ANS 416398', value: '339-CCE 2024 INTERMED ANS 416398' },
  { label: '34-OUROMED', value: '34-OUROMED' },
  { label: '340-CCE 2024 CASSI ANS 346659', value: '340-CCE 2024 CASSI ANS 346659' },
  { label: '341-CCE 2024 SAMEL ANS 367095', value: '341-CCE 2024 SAMEL ANS 367095' },
  { label: '342-CCE2024 NORDESTE SAUDE 41275', value: '342-CCE2024 NORDESTE SAUDE 41275' },
  { label: '343-BLUE SAUDE CCE 2024', value: '343-BLUE SAUDE CCE 2024' },
  { label: '344-AMPLA ANS 42272-0', value: '344-AMPLA ANS 42272-0' },
  { label: '345-CCE 2024 AMPLA ANS 422720', value: '345-CCE 2024 AMPLA ANS 422720' },
  { label: '346-AMEPLAN - ANS 394734', value: '346-AMEPLAN - ANS 394734' },
  { label: '347-ALICE - ANS 421928', value: '347-ALICE - ANS 421928' },
  { label: '348-AMHEMED - 421731', value: '348-AMHEMED - 421731' },
  { label: '349-ANA COSTA SAUDE - ANS 360244', value: '349-ANA COSTA SAUDE - ANS 360244' },
  { label: '35-BAMERINDUS', value: '35-BAMERINDUS' },
  { label: '350-ASSIM SAUDE - ANS 309222', value: '350-ASSIM SAUDE - ANS 309222' },
  { label: '351-ATIVIA SAUDE - ANS 320510', value: '351-ATIVIA SAUDE - ANS 320510' },
  { label: '352-BIOVIDA - ANS 415111', value: '352-BIOVIDA - ANS 415111' },
  { label: '353-CABERJ - ANS 415774', value: '353-CABERJ - ANS 415774' },
  { label: '354-CABESP - ANS 352331', value: '354-CABESP - ANS 352331' },
  { label: '355-CARE PLUS - ANS 379956', value: '355-CARE PLUS - ANS 379956' },
  { label: '356-CEMERU - ANS 401081', value: '356-CEMERU - ANS 401081' },
  { label: '357-CLIMED ATIBAIA - ANS 350699', value: '357-CLIMED ATIBAIA - ANS 350699' },
  { label: '358-GOCARE - ANS 422681', value: '358-GOCARE - ANS 422681' },
  { label: '359-LEVE SAUDE - ANS 422339', value: '359-LEVE SAUDE - ANS 422339' },
  { label: '36-INTERCLINICA', value: '36-INTERCLINICA' },
  { label: '361-MEDISERVICE - ANS 333689', value: '361-MEDISERVICE - ANS 333689' },
  { label: '362-OMINT - ANS 359661', value: '362-OMINT - ANS 359661' },
  { label: '363-HBC - ANS 414352', value: '363-HBC - ANS 414352' },
  { label: '364-PLENA SAUDE - ANS 348830', value: '364-PLENA SAUDE - ANS 348830' },
  { label: '365-PORTO SEGURO - ANS 582', value: '365-PORTO SEGURO - ANS 582' },
  { label: '366-PREVENT SENIO - ANS 302147', value: '366-PREVENT SENIO - ANS 302147' },
  { label: '367-SAMARITANO SAUDE - ANS 411256', value: '367-SAMARITANO SAUDE - ANS 411256' },
  { label: '368-SAMI - ANS 422398', value: '368-SAMI - ANS 422398' },
  { label: '369-SANTA CASA DE MAUA - ANS 421197', value: '369-SANTA CASA DE MAUA - ANS 421197' },
  { label: '37-INTERMED', value: '37-INTERMED' },
  { label: '370-SANTA HELENA - ANS 355097', value: '370-SANTA HELENA - ANS 355097' },
  { label: '371-SAO CRISTOVAO - ANS 314218', value: '371-SAO CRISTOVAO - ANS 314218' },
  { label: '372-SAO MIGUEL SAUDE - ANS 325236', value: '372-SAO MIGUEL SAUDE - ANS 325236' },
  { label: '373-SAUDE BENEFICENCIA - ANS 417530', value: '373-SAUDE BENEFICENCIA - ANS 417530' },
  { label: '374-SAUDE SANTA TEREZA - ANS 414930', value: '374-SAUDE SANTA TEREZA - ANS 414930' },
  { label: '375-SOBAM - ANS 326500', value: '375-SOBAM - ANS 326500' },
  { label: '376-TRASMONTANO - ANS 303623', value: '376-TRASMONTANO - ANS 303623' },
  { label: '377-UNICA - 421944', value: '377-UNICA - 421944' },
  { label: '38-SEMPER', value: '38-SEMPER' },
  { label: '4-CAMED', value: '4-CAMED' },
  { label: '40-PLAMED', value: '40-PLAMED' },
  { label: '42-PROSAUDE', value: '42-PROSAUDE' },
  { label: '43-GRUPO SAUDE ANS 41280-5', value: '43-GRUPO SAUDE ANS 41280-5' },
  { label: '45-LONG LIFE', value: '45-LONG LIFE' },
  { label: '46-MULT LIFE', value: '46-MULT LIFE' },
  { label: '47-MEDPLAN - ANS 337510', value: '47-MEDPLAN - ANS 337510' },
  { label: '499-BLUE SAUDE 423173', value: '499-BLUE SAUDE 423173' },
  { label: '501-CCE UNIMED MANAUS', value: '501-CCE UNIMED MANAUS' },
  { label: '502-DONA BALBINA - ANS 416495', value: '502-DONA BALBINA - ANS 416495' },
  {
    label: '503-SANTA CASA PIRACICABA - ANS 354562',
    value: '503-SANTA CASA PIRACICABA - ANS 354562',
  },
  { label: '504-SERMED - ANS 303739', value: '504-SERMED - ANS 303739' },
  { label: '505-AUSTA - ANS 327417', value: '505-AUSTA - ANS 327417' },
  { label: '506-SANTA CASA R. PRETO - 417947', value: '506-SANTA CASA R. PRETO - 417947' },
  { label: '507-SANTA CASA DE TANQUARINGA', value: '507-SANTA CASA DE TANQUARINGA' },
  { label: '509-ABRAMGE', value: '509-ABRAMGE' },
  { label: '510-AGROS', value: '510-AGROS' },
  { label: '511-AMAGIS SAÚDE', value: '511-AMAGIS SAÚDE' },
  { label: '512-ANCORA ASSES CONS LTDA (VERITAS)', value: '512-ANCORA ASSES CONS LTDA (VERITAS)' },
  {
    label: '513-BRASIL ASSISTENCIA (MAPFRE ASSISTÊNCIA)',
    value: '513-BRASIL ASSISTENCIA (MAPFRE ASSISTÊNCIA)',
  },
  { label: '514-CAARF', value: '514-CAARF' },
  { label: '515-CARE PLUS', value: '515-CARE PLUS' },
  { label: '516-CENIBRA', value: '516-CENIBRA' },
  { label: '517-CETUS - ONCOLOGIA', value: '517-CETUS - ONCOLOGIA' },
  { label: '518-DESBAN', value: '518-DESBAN' },
  { label: '519-FUNDAÇÃO SAÚDE ITAÚ', value: '519-FUNDAÇÃO SAÚDE ITAÚ' },
  { label: '52-MEDIAL SAUDE', value: '52-MEDIAL SAUDE' },
  { label: '520-FUNDAFFEMG', value: '520-FUNDAFFEMG' },
  { label: '521-INTER PARTNER (AXA ASSISTANCE)', value: '521-INTER PARTNER (AXA ASSISTANCE)' },
  { label: '522-PÁTIO SAVASSI', value: '522-PÁTIO SAVASSI' },
  { label: '523-PLAN - ASSISTE REGIONAL (MPT)', value: '523-PLAN - ASSISTE REGIONAL (MPT)' },
  { label: '524-PLAN-CNEN/CDTN', value: '524-PLAN-CNEN/CDTN' },
  { label: '525-SICOOB CREDIFIEMG', value: '525-SICOOB CREDIFIEMG' },
  { label: '526-SICOOBJUS-MP', value: '526-SICOOBJUS-MP' },
  {
    label: '527-SOMPO SAÚDE (ANTIGA MARÍTIMA SAÚDE)',
    value: '527-SOMPO SAÚDE (ANTIGA MARÍTIMA SAÚDE)',
  },
  { label: '528-TRAVEL ACE ASSISTANCE', value: '528-TRAVEL ACE ASSISTANCE' },
  { label: '529-UNAFISCO SAÚDE', value: '529-UNAFISCO SAÚDE' },
  { label: '530-UNIBANCO AIG SAÚDE', value: '530-UNIBANCO AIG SAÚDE' },
  { label: '531-WORLD ASSIST', value: '531-WORLD ASSIST' },
  { label: '532-WORLD MEDICAL', value: '532-WORLD MEDICAL' },
  { label: '533-FUNDAFFEMG', value: '533-FUNDAFFEMG' },
  { label: '534-HOSPITAL DA VARGEM GRANDE DO SUL', value: '534-HOSPITAL DA VARGEM GRANDE DO SUL' },
  { label: '535-INB', value: '535-INB' },
  { label: '536-NDI SP', value: '536-NDI SP' },
  {
    label: '537-POLICIA MILITAR ESTADO DE MINAS GERAIS',
    value: '537-POLICIA MILITAR ESTADO DE MINAS GERAIS',
  },
  {
    label: '538-FUNDAÇÃO PAMPULHA FUNDACAO SAUDE ITAU',
    value: '538-FUNDAÇÃO PAMPULHA FUNDACAO SAUDE ITAU',
  },
  { label: '539-UNIFAI', value: '539-UNIFAI' },
  { label: '54-HOSPITAL SAO LUIS', value: '54-HOSPITAL SAO LUIS' },
  { label: '540-MEDGOLD', value: '540-MEDGOLD' },
  { label: '541-LEVMED SAUDE - ANS 422321', value: '541-LEVMED SAUDE - ANS 422321' },
  { label: '542-A.P.P.A.I', value: '542-A.P.P.A.I' },
  { label: '543-AAZ SERVICOS MEDICOS', value: '543-AAZ SERVICOS MEDICOS' },
  { label: '544-ABAS SAÚDE', value: '544-ABAS SAÚDE' },
  { label: '545-ABERTA SAÚDE', value: '545-ABERTA SAÚDE' },
  { label: '546-ABMED', value: '546-ABMED' },
  { label: '547-ACIESP', value: '547-ACIESP' },
  { label: '548-ADVANCE', value: '548-ADVANCE' },
  { label: '549-AERONÁUTICA', value: '549-AERONÁUTICA' },
  { label: '55-AGUANAMBI SAUDE', value: '55-AGUANAMBI SAUDE' },
  { label: '550-AFPESP', value: '550-AFPESP' },
  { label: '551-AGAPE SAUDE', value: '551-AGAPE SAUDE' },
  { label: '552-AGUA SANTA / ESPORTE', value: '552-AGUA SANTA / ESPORTE' },
  { label: '553-ALICE (QSAÚDE)', value: '553-ALICE (QSAÚDE)' },
  { label: '554-ALVORECER', value: '554-ALVORECER' },
  { label: '555-AMAFRESP', value: '555-AMAFRESP' },
  { label: '556-AMEPLAN', value: '556-AMEPLAN' },
  { label: '557-AMOR E SAUDE CAMPO LIMPO', value: '557-AMOR E SAUDE CAMPO LIMPO' },
  { label: '558-AMOR E SAUDE MBOI MIRIM', value: '558-AMOR E SAUDE MBOI MIRIM' },
  { label: '559-AMOR E SAUDE PIRAJUSSARA', value: '559-AMOR E SAUDE PIRAJUSSARA' },
  { label: '56-HOSPLAN-PB', value: '56-HOSPLAN-PB' },
  { label: '560-AMOR E SAUDE TABOÃO DA SERRA', value: '560-AMOR E SAUDE TABOÃO DA SERRA' },
  { label: '561-AMOR E SAUDE VILA ANDRADE', value: '561-AMOR E SAUDE VILA ANDRADE' },
  { label: '562-AMOR SAUDE CAPELA DO SOCORRO', value: '562-AMOR SAUDE CAPELA DO SOCORRO' },
  { label: '563-AMOR SAUDE PIRAJUSSARA', value: '563-AMOR SAUDE PIRAJUSSARA' },
  { label: '564-AMR ECOLE', value: '564-AMR ECOLE' },
  { label: '565-APAS ITAPETININGA', value: '565-APAS ITAPETININGA' },
  { label: '566-APAS SANTOS', value: '566-APAS SANTOS' },
  { label: '567-APAS SAUDE SOROCABA', value: '567-APAS SAUDE SOROCABA' },
  { label: '568-APPAI', value: '568-APPAI' },
  { label: '569-ARLONSP SERVICOS MEDICOS LTDA', value: '569-ARLONSP SERVICOS MEDICOS LTDA' },
  { label: '57-HC SAUDE', value: '57-HC SAUDE' },
  {
    label: '570-ARSENAL DE GUERRA SP (FUSEX BARUERI)',
    value: '570-ARSENAL DE GUERRA SP (FUSEX BARUERI)',
  },
  { label: '571-AS REG US SERV SAUDE', value: '571-AS REG US SERV SAUDE' },
  { label: '572-ASONET', value: '572-ASONET' },
  {
    label: '573-ASS TORRE DE VIGIA DE BIBLIA E TRATADOS',
    value: '573-ASS TORRE DE VIGIA DE BIBLIA E TRATADOS',
  },
  { label: '574-ASS. DOS FERROVIARIOS', value: '574-ASS. DOS FERROVIARIOS' },
  { label: '575-ASS. DOS FUNC. PÚBLICOS DE SBC', value: '575-ASS. DOS FUNC. PÚBLICOS DE SBC' },
  { label: '576-ASSEFAZ', value: '576-ASSEFAZ' },
  { label: '577-ASSIM SAUDE', value: '577-ASSIM SAUDE' },
  { label: '578-ASSINA GLOBALMED', value: '578-ASSINA GLOBALMED' },
  { label: '579-ASSIST CARD DO BRASIL', value: '579-ASSIST CARD DO BRASIL' },
  {
    label: '58-HOSPITAL ADVENTISTA BELEM ANS 40655-4',
    value: '58-HOSPITAL ADVENTISTA BELEM ANS 40655-4',
  },
  { label: '580-ASSIST RIO DE JANEIRO', value: '580-ASSIST RIO DE JANEIRO' },
  {
    label: '581-ASSISTÊNCIA MÉDICA SÃO MIGUEL LTDA',
    value: '581-ASSISTÊNCIA MÉDICA SÃO MIGUEL LTDA',
  },
  {
    label: '582-ASSOCIAÇÃO DE SAÚDE HOLAMBRA - ASH',
    value: '582-ASSOCIAÇÃO DE SAÚDE HOLAMBRA - ASH',
  },
  {
    label: '583-ASSOCI REG DOS USUÁRIOS DE SERV.SAÚDE SP',
    value: '583-ASSOCI REG DOS USUÁRIOS DE SERV.SAÚDE SP',
  },
  {
    label: '584-ASSOCIAÇÃO SANTA CASA SAUDE S J DOS CAMP',
    value: '584-ASSOCIAÇÃO SANTA CASA SAUDE S J DOS CAMP',
  },
  { label: '585-ATIVA SERVIÇOS DE SAÚDE S/A', value: '585-ATIVA SERVIÇOS DE SAÚDE S/A' },
  { label: '586-AXA ASSISTANCE', value: '586-AXA ASSISTANCE' },
  { label: '587-BEIGENE USA, INC', value: '587-BEIGENE USA, INC' },
  {
    label: '588-BEM MARKETING NEGOCIOS LTDA PRIME SAUDE',
    value: '588-BEM MARKETING NEGOCIOS LTDA PRIME SAUDE',
  },
  { label: '589-BENEFICÊNCIA PORTUGUESA', value: '589-BENEFICÊNCIA PORTUGUESA' },
  { label: '59-SOCIEDADE SAO BRAZ', value: '59-SOCIEDADE SAO BRAZ' },
  { label: '590-BIOVIDA SAÚDE', value: '590-BIOVIDA SAÚDE' },
  {
    label: '591-BMS - CHECKMATEL (ENSINO E PESQUISA)',
    value: '591-BMS - CHECKMATEL (ENSINO E PESQUISA)',
  },
  { label: '592-BNDES - FAPES', value: '592-BNDES - FAPES' },
  { label: '593-BRADESCO OPERADORA', value: '593-BRADESCO OPERADORA' },
  {
    label: '594-BRASIL ASSISTENCIA (MAPFRE BRASIL)',
    value: '594-BRASIL ASSISTENCIA (MAPFRE BRASIL)',
  },
  {
    label: '595-CABESP CX BENEFICIENTE DOS FUNCIONARIOS',
    value: '595-CABESP CX BENEFICIENTE DOS FUNCIONARIOS',
  },
  { label: '596-CAIXA DE SAÚDE - SÃO VICENTE', value: '596-CAIXA DE SAÚDE - SÃO VICENTE' },
  {
    label: '597-CAIXA ECONOMICA FEDERAL (SAÚDE CAIXA)',
    value: '597-CAIXA ECONOMICA FEDERAL (SAÚDE CAIXA)',
  },
  { label: '598-CAMARJ', value: '598-CAMARJ' },
  { label: '599-CAMPREV', value: '599-CAMPREV' },
  { label: '60-SAMP - ANS 342033', value: '60-SAMP - ANS 342033' },
  { label: '600-CAPEP SAÚDE', value: '600-CAPEP SAÚDE' },
  { label: '601-CAPESESP', value: '601-CAPESESP' },
  {
    label: '602-CARE PLUS MEDI. ASSISTENCIAL S/S LTDA',
    value: '602-CARE PLUS MEDI. ASSISTENCIAL S/S LTDA',
  },
  {
    label: '603-CARING SAUDE DE ASSISTENCIA MEDICA LTDA',
    value: '603-CARING SAUDE DE ASSISTENCIA MEDICA LTDA',
  },
  { label: '604-CASEMBRAPA', value: '604-CASEMBRAPA' },
  { label: '605-CAURJ', value: '605-CAURJ' },
  { label: '606-CCG', value: '606-CCG' },
  {
    label: '607-CDMED CENTRO DE DIAGNÓSTICOS MÉDICOS',
    value: '607-CDMED CENTRO DE DIAGNÓSTICOS MÉDICOS',
  },
  { label: '608-CEAM BRASIL PLANOS DE SAUDE SA', value: '608-CEAM BRASIL PLANOS DE SAUDE SA' },
  { label: '609-CEMEPRE - 5571 (OPENLINE)', value: '609-CEMEPRE - 5571 (OPENLINE)' },
  { label: '610-CEMERU', value: '610-CEMERU' },
  { label: '611-CENTRAL NACIONAL UNIMED', value: '611-CENTRAL NACIONAL UNIMED' },
  { label: '612-CENTRO CLINICO LTDA', value: '612-CENTRO CLINICO LTDA' },
  { label: '613-CENTRO DE ENSINO ESPINOZA LTDA', value: '613-CENTRO DE ENSINO ESPINOZA LTDA' },
  {
    label: '614-CENTRO DE ESPECIALIDADES BRASIL SAÚDE',
    value: '614-CENTRO DE ESPECIALIDADES BRASIL SAÚDE',
  },
  { label: '615-CERPO', value: '615-CERPO' },
  { label: '616-CET', value: '616-CET' },
  { label: '617-CETESB', value: '617-CETESB' },
  { label: '618-CHECK UP TRANSPETRO', value: '618-CHECK UP TRANSPETRO' },
  { label: '619-CIA SUZANO', value: '619-CIA SUZANO' },
  { label: '62-VIVA SAUDE', value: '62-VIVA SAUDE' },
  { label: '620-CLARO', value: '620-CLARO' },
  {
    label: '621-CLARO S.A AUTO GESTAO EM SAUDE - AMO',
    value: '621-CLARO S.A AUTO GESTAO EM SAUDE - AMO',
  },
  { label: '622-CLIMEPE TOTAL LTDA', value: '622-CLIMEPE TOTAL LTDA' },
  { label: '623-CLINICA AMOR E SAUDE', value: '623-CLINICA AMOR E SAUDE' },
  { label: '624-CLINICA DA FAMILIA', value: '624-CLINICA DA FAMILIA' },
  { label: '625-CLÍNICA MENINO JESUS', value: '625-CLÍNICA MENINO JESUS' },
  { label: '626-CLINICA NEPHRON', value: '626-CLINICA NEPHRON' },
  { label: '627-CLINICA SETTE', value: '627-CLINICA SETTE' },
  { label: '628-CLINIPAN', value: '628-CLINIPAN' },
  { label: '629-CLUBE DE DESCONTOS', value: '629-CLUBE DE DESCONTOS' },
  { label: '63-CARIRI SAUDE', value: '63-CARIRI SAUDE' },
  {
    label: '630-CLUBECORP SAUDE - 5573 (SAÚDE DA GENTE)',
    value: '630-CLUBECORP SAUDE - 5573 (SAÚDE DA GENTE)',
  },
  { label: '631-CNEN IPEN', value: '631-CNEN IPEN' },
  { label: '632-COOPUS PLANOS DE SAÚDE', value: '632-COOPUS PLANOS DE SAÚDE' },
  { label: '633-CRUZ AZUL SAÚDE', value: '633-CRUZ AZUL SAÚDE' },
  { label: '634-CUSTO HOSPITALAR', value: '634-CUSTO HOSPITALAR' },
  { label: '635-CV GENES - HAOC', value: '635-CV GENES - HAOC' },
  { label: '636-DANAMED', value: '636-DANAMED' },
  { label: '637-DOCCTOR MED SANTA CECÍLIA', value: '637-DOCCTOR MED SANTA CECÍLIA' },
  { label: '638-DONA BALBINA', value: '638-DONA BALBINA' },
  { label: '639-DONA SAUDE CLINICAS LTDA', value: '639-DONA SAUDE CLINICAS LTDA' },
  { label: '64-SUDAMERIS', value: '64-SUDAMERIS' },
  { label: '640-DR EXAME MEDICINA DIAGNÓSTICA', value: '640-DR EXAME MEDICINA DIAGNÓSTICA' },
  { label: '641-DR VIDA CLINICA', value: '641-DR VIDA CLINICA' },
  { label: '642-DR. DE TODOS', value: '642-DR. DE TODOS' },
  { label: '643-DR. ESPECIALISTA', value: '643-DR. ESPECIALISTA' },
  { label: '644-DR. EXAME', value: '644-DR. EXAME' },
  { label: '645-ECONÔMICA', value: '645-ECONÔMICA' },
  { label: '646-ECONOMUS', value: '646-ECONOMUS' },
  { label: '647-ELA ASSISTÊNCIA MÉDICA', value: '647-ELA ASSISTÊNCIA MÉDICA' },
  { label: '648-ELETROBRÁS', value: '648-ELETROBRÁS' },
  { label: '649-ELETROS', value: '649-ELETROS' },
  { label: '65-MEDISERVICE- ANS 442959/03-1', value: '65-MEDISERVICE- ANS 442959/03-1' },
  { label: '650-EMBRATEL - CLARO', value: '650-EMBRATEL - CLARO' },
  { label: '651-EMBRATEL - PAME', value: '651-EMBRATEL - PAME' },
  { label: '652-EMBRATEL - TELOS', value: '652-EMBRATEL - TELOS' },
  { label: '653-EPHARMA', value: '653-EPHARMA' },
  { label: '654-ETERNIT', value: '654-ETERNIT' },
  { label: '655-EURO CENTER', value: '655-EURO CENTER' },
  { label: '656-EURO-CENTER LTDA', value: '656-EURO-CENTER LTDA' },
  {
    label: '657-EUROFARMA - RELIEF (ENSINO E PESQUISA)',
    value: '657-EUROFARMA - RELIEF (ENSINO E PESQUISA)',
  },
  { label: '658-EVERCROSS', value: '658-EVERCROSS' },
  { label: '659-EXAMINE JÁ', value: '659-EXAMINE JÁ' },
  { label: '66-SMILE', value: '66-SMILE' },
  { label: '660-FAPES', value: '660-FAPES' },
  { label: '661-FENIX MEDICAL', value: '661-FENIX MEDICAL' },
  { label: '662-FIAT', value: '662-FIAT' },
  { label: '663-FILOO', value: '663-FILOO' },
  { label: '664-FIO SAÚDE', value: '664-FIO SAÚDE' },
  { label: '665-FLUYR SAUDÁVEL', value: '665-FLUYR SAUDÁVEL' },
  { label: '666-FUSEX - JUNDIAÍ', value: '666-FUSEX - JUNDIAÍ' },
  { label: '667-FUSEX BARUERI', value: '667-FUSEX BARUERI' },
  { label: '67-BRADESCO SAUDE', value: '67-BRADESCO SAUDE' },
  { label: '675-GLADE - SCIENCE VALLEY', value: '675-GLADE - SCIENCE VALLEY' },
  { label: '676-GOCARE PLANOS DE SAUDE LTDA', value: '676-GOCARE PLANOS DE SAUDE LTDA' },
  { label: '677-GOLDMED', value: '677-GOLDMED' },
  {
    label: '678-GRUPO DE ARTILHARIA CAMPANHA LEVE ITU',
    value: '678-GRUPO DE ARTILHARIA CAMPANHA LEVE ITU',
  },
  { label: '679-H.B SAUDE S/A', value: '679-H.B SAUDE S/A' },
  { label: '68-HOSPITAL SAO CAMILO', value: '68-HOSPITAL SAO CAMILO' },
  { label: '680-HAPPYMED PLANO DE SAÚDE LTDA', value: '680-HAPPYMED PLANO DE SAÚDE LTDA' },
  { label: '681-HBC SAÚDE', value: '681-HBC SAÚDE' },
  { label: '682-HMASP', value: '682-HMASP' },
  { label: '683-HOSPITAL ADVENTISTA DE SÃO PAULO', value: '683-HOSPITAL ADVENTISTA DE SÃO PAULO' },
  { label: '684-HOSPITAÚ', value: '684-HOSPITAÚ' },
  { label: '685-IAVC', value: '685-IAVC' },
  { label: '686-IFROM', value: '686-IFROM' },
  { label: '687-IMASF', value: '687-IMASF' },
  { label: '688-IN HEALTH', value: '688-IN HEALTH' },
  { label: '689-INDEPENDENCE CARD', value: '689-INDEPENDENCE CARD' },
  { label: '69-MULTICLINICA', value: '69-MULTICLINICA' },
  { label: '690-INSTITUTO DOUTOR ARNALDO', value: '690-INSTITUTO DOUTOR ARNALDO' },
  { label: '691-INSTITUTO RESPIRAR', value: '691-INSTITUTO RESPIRAR' },
  {
    label: '692-INSTITUTO SÍNTASE SAÚDE E TRABALHO',
    value: '692-INSTITUTO SÍNTASE SAÚDE E TRABALHO',
  },
  { label: '693-INTERCLÍNICAS', value: '693-INTERCLÍNICAS' },
  { label: '694-INTERGLOBAL SAÚDE', value: '694-INTERGLOBAL SAÚDE' },
  { label: '695-INTERMASTER AMBULATORIAL', value: '695-INTERMASTER AMBULATORIAL' },
  { label: '696-INTERMEDICI', value: '696-INTERMEDICI' },
  { label: '697-INVESTPREV SEGURADORA', value: '697-INVESTPREV SEGURADORA' },
  { label: '698-IORT MAUÁ', value: '698-IORT MAUÁ' },
  { label: '699-IORT RIBEIRÃO PIRES', value: '699-IORT RIBEIRÃO PIRES' },
  { label: '7-GOLDEN CROSS', value: '7-GOLDEN CROSS' },
  { label: '70-AGF', value: '70-AGF' },
  { label: '700-IPALERJ', value: '700-IPALERJ' },
  { label: '701-IPREF', value: '701-IPREF' },
  {
    label: '702-IRMANDADE DE SANTA CASA RIO CLARO',
    value: '702-IRMANDADE DE SANTA CASA RIO CLARO',
  },
  {
    label: '703-IRMANDADE MISERICOR DE PORTO FERREIRA',
    value: '703-IRMANDADE MISERICOR DE PORTO FERREIRA',
  },
  { label: '704-IRMANDADE SANTA CASA', value: '704-IRMANDADE SANTA CASA' },
  { label: '705-ISPOF', value: '705-ISPOF' },
  {
    label: '706-KOVR SEGURADORA S.A. (INVESTPREV)',
    value: '706-KOVR SEGURADORA S.A. (INVESTPREV)',
  },
  { label: '707-LAB FOR LIFE', value: '707-LAB FOR LIFE' },
  { label: '708-LEVE SAÚDE', value: '708-LEVE SAÚDE' },
  { label: '709-LIFE EMPRESARIAL SAUDE LTDA', value: '709-LIFE EMPRESARIAL SAUDE LTDA' },
  { label: '71-GENERALLI', value: '71-GENERALLI' },
  { label: '710-LIVRI SAUDE', value: '710-LIVRI SAUDE' },
  { label: '711-LUSTRES YAMAMURA', value: '711-LUSTRES YAMAMURA' },
  { label: '712-MAPFRE ASSISTÊNCIA', value: '712-MAPFRE ASSISTÊNCIA' },
  { label: '713-MED SELECT', value: '713-MED SELECT' },
  { label: '714-MED TOUR SAÚDE', value: '714-MED TOUR SAÚDE' },
  { label: '715-MEDIC360 SERVIÇOS MÉDICOS LTDA', value: '715-MEDIC360 SERVIÇOS MÉDICOS LTDA' },
  { label: '716-MEDICAL RIO', value: '716-MEDICAL RIO' },
  { label: '717-MEDICAL TEC HEALTH', value: '717-MEDICAL TEC HEALTH' },
  { label: '718-MEDIPLAN ASSISTÊNCIAL', value: '718-MEDIPLAN ASSISTÊNCIAL' },
  {
    label: '719-MEDISERVICE ADM.DE PLANOS DE SAUDE LTDA',
    value: '719-MEDISERVICE ADM.DE PLANOS DE SAUDE LTDA',
  },
  { label: '72-PORTO SEGURO', value: '72-PORTO SEGURO' },
  { label: '720-MEDSÊNIOR', value: '720-MEDSÊNIOR' },
  { label: '721-MEMORIAL SAUDE', value: '721-MEMORIAL SAUDE' },
  { label: '722-METRUS', value: '722-METRUS' },
  {
    label: '723-METRUS INSTITUTO DE SEGURIDADE SOCIAL',
    value: '723-METRUS INSTITUTO DE SEGURIDADE SOCIAL',
  },
  {
    label: '724-MIX FRANCHISING LTDA - 5547 (DR EXAME)',
    value: '724-MIX FRANCHISING LTDA - 5547 (DR EXAME)',
  },
  { label: '725-MONDIAL ASSITANCE (AWP)', value: '725-MONDIAL ASSITANCE (AWP)' },
  { label: '726-MONDIAL SERVIÇOS LTDA', value: '726-MONDIAL SERVIÇOS LTDA' },
  { label: '727-MULTI MED', value: '727-MULTI MED' },
  { label: '728-MUNDIAL CARD', value: '728-MUNDIAL CARD' },
  { label: '729-MUTUA MAGISTRADOS', value: '729-MUTUA MAGISTRADOS' },
  { label: '73-OURO SAUDE', value: '73-OURO SAUDE' },
  { label: '730-NOTRE DAME INTERMEDICA MINAS S.A', value: '730-NOTRE DAME INTERMEDICA MINAS S.A' },
  { label: '731-NOTREMEDICAL', value: '731-NOTREMEDICAL' },
  {
    label: '732-NOVARTIS - PERFECT (ENSINO E PESQUISA)',
    value: '732-NOVARTIS - PERFECT (ENSINO E PESQUISA)',
  },
  { label: '733-OMINT SERVICOS DE SAUDE LTDA', value: '733-OMINT SERVICOS DE SAUDE LTDA' },
  { label: '734-OPEN LINE', value: '734-OPEN LINE' },
  { label: '735-ORIZON', value: '735-ORIZON' },
  { label: '736-PACTUM SAÚDE', value: '736-PACTUM SAÚDE' },
  { label: '737-PARTEMED RUDGE RAMOS', value: '737-PARTEMED RUDGE RAMOS' },
  { label: '738-PLADISA', value: '738-PLADISA' },
  { label: '739-PLAN ASSISTE', value: '739-PLAN ASSISTE' },
  { label: '74-GAMEC', value: '74-GAMEC' },
  { label: '740-POLICLIN SAÚDE', value: '740-POLICLIN SAÚDE' },
  { label: '742-PORTOMED - PORTO SEGURO', value: '742-PORTOMED - PORTO SEGURO' },
  { label: '743-POSTAL CORREIOS', value: '743-POSTAL CORREIOS' },
  { label: '744-POSTAL SAÚDE', value: '744-POSTAL SAÚDE' },
  { label: '745-PREFEITURA DE VALINHOS', value: '745-PREFEITURA DE VALINHOS' },
  { label: '746-PREVENT SENIOR', value: '746-PREVENT SENIOR' },
  { label: '747-PRIME SAÚDE', value: '747-PRIME SAÚDE' },
  {
    label: '748-PROASA - PROGRAMA ADVENTISTA DE SAUDE',
    value: '748-PROASA - PROGRAMA ADVENTISTA DE SAUDE',
  },
  { label: '749-QSAUDE', value: '749-QSAUDE' },
  { label: '75-FREE LIFE', value: '75-FREE LIFE' },
  { label: '750-QSAUDE - ALICE', value: '750-QSAUDE - ALICE' },
  { label: '751-QUALLYPROMED', value: '751-QUALLYPROMED' },
  {
    label: '752-REAL GRANDEZA FUND. DE PREV. E AS',
    value: '752-REAL GRANDEZA FUND. DE PREV. E AS',
  },
  { label: '753-RH VIDA', value: '753-RH VIDA' },
  { label: '754-SABESPREV', value: '754-SABESPREV' },
  { label: '755-SALUD VALOR', value: '755-SALUD VALOR' },
  { label: '756-SALUTAR', value: '756-SALUTAR' },
  { label: '757-SAMARITANO', value: '757-SAMARITANO' },
  {
    label: '758-SAMEDIL SERVIÇOS DE ATENDIMENTO MÉDICO',
    value: '758-SAMEDIL SERVIÇOS DE ATENDIMENTO MÉDICO',
  },
  { label: '759-SAMI ASSISTÊNCIA MÉDICA LTDA', value: '759-SAMI ASSISTÊNCIA MÉDICA LTDA' },
  { label: '76-CLINIHAUER', value: '76-CLINIHAUER' },
  {
    label: '760-SANAMED - SAÚDE SANTO ANTONIO LTDA.',
    value: '760-SANAMED - SAÚDE SANTO ANTONIO LTDA.',
  },
  {
    label: '761-SANOFI - NOTUS (ENSINO E PESQUISA)',
    value: '761-SANOFI - NOTUS (ENSINO E PESQUISA)',
  },
  { label: '762-SANTA CASA DE MAUA', value: '762-SANTA CASA DE MAUA' },
  {
    label: '763-SANTA CASA DE MISERICÓRDIA BRAGANÇA SP',
    value: '763-SANTA CASA DE MISERICÓRDIA BRAGANÇA SP',
  },
  {
    label: '764-SANTA CASA DE MISERICORDIA DE ITAPEVA',
    value: '764-SANTA CASA DE MISERICORDIA DE ITAPEVA',
  },
  { label: '765-SANTA CASA DE SANTOS', value: '765-SANTA CASA DE SANTOS' },
  { label: '766-SANTA CASA DE VALINHOS', value: '766-SANTA CASA DE VALINHOS' },
  { label: '767-SANTA HELENA SAÚDE', value: '767-SANTA HELENA SAÚDE' },
  {
    label: '768-SANTO ANDRE PLANOS DE ASSIST MEDICA LTDA',
    value: '768-SANTO ANDRE PLANOS DE ASSIST MEDICA LTDA',
  },
  { label: '769-SANWEY', value: '769-SANWEY' },
  { label: '770-SÃO CAMILO DE ITÚ', value: '770-SÃO CAMILO DE ITÚ' },
  { label: '771-SÃO LUCAS SAÚDE', value: '771-SÃO LUCAS SAÚDE' },
  { label: '772-SAO MIGUEL SAUDE', value: '772-SAO MIGUEL SAUDE' },
  { label: '773-SAÚDE BRASIL', value: '773-SAÚDE BRASIL' },
  { label: '774-SAUDE FAMILIAR', value: '774-SAUDE FAMILIAR' },
  {
    label: '775-SAÚDE PETROBRÁS (PETROBRAS EDISE)',
    value: '775-SAÚDE PETROBRÁS (PETROBRAS EDISE)',
  },
  { label: '776-SAUDE SECULAR', value: '776-SAUDE SECULAR' },
  { label: '777-SBC SAÚDE', value: '777-SBC SAÚDE' },
  { label: '778-SEARA - ANHANGURA', value: '778-SEARA - ANHANGURA' },
  { label: '779-SEARA ALIMENTOS LTDA', value: '779-SEARA ALIMENTOS LTDA' },
  { label: '780-SEARA CD ANHANGUERA', value: '780-SEARA CD ANHANGUERA' },
  { label: '781-SEARA OSASCO', value: '781-SEARA OSASCO' },
  { label: '782-SECCOR', value: '782-SECCOR' },
  { label: '783-SEEG - SIND EMP ESTAC GARAGENS', value: '783-SEEG - SIND EMP ESTAC GARAGENS' },
  { label: '784-SEPACO', value: '784-SEPACO' },
  { label: '785-SEPACO AUTOGESTÃO', value: '785-SEPACO AUTOGESTÃO' },
  { label: '786-SEPACO SAÚDE', value: '786-SEPACO SAÚDE' },
  { label: '787-SEPREV', value: '787-SEPREV' },
  { label: '788-SIEMACO ABC', value: '788-SIEMACO ABC' },
  { label: '789-SIND TAXISTAS AUTONOMOS SP', value: '789-SIND TAXISTAS AUTONOMOS SP' },
  { label: '790-SIND TRABALHADORES', value: '790-SIND TRABALHADORES' },
  { label: '791-SINDESPORTE', value: '791-SINDESPORTE' },
  { label: '792-SINTHORESP', value: '792-SINTHORESP' },
  { label: '793-SIPLAN SAFETY', value: '793-SIPLAN SAFETY' },
  {
    label: '794-Sistemas e Planos (MEDICAL HEALTH)',
    value: '794-Sistemas e Planos (MEDICAL HEALTH)',
  },
  { label: '795-SOLUÇÃO E ASSESSORIA', value: '795-SOLUÇÃO E ASSESSORIA' },
  { label: '796-SOMPO SAUDE', value: '796-SOMPO SAUDE' },
  { label: '797-SUL AMERICA SAÚDE', value: '797-SUL AMERICA SAÚDE' },
  { label: '798-SURMOUNT - LILLY', value: '798-SURMOUNT - LILLY' },
  { label: '799-TELOS', value: '799-TELOS' },
  { label: '8-CASSI', value: '8-CASSI' },
  { label: '800-TOTAL MEDCARE', value: '800-TOTAL MEDCARE' },
  { label: '801-TOTAL SAÚDE M BOI', value: '801-TOTAL SAÚDE M BOI' },
  { label: '802-TRASMONTANO', value: '802-TRASMONTANO' },
  { label: '803-UBB UNIÃO BRASILEIRA BENEFICENTE', value: '803-UBB UNIÃO BRASILEIRA BENEFICENTE' },
  { label: '804-UNICA ASSISTENCIA MEDICA LTDA', value: '804-UNICA ASSISTENCIA MEDICA LTDA' },
  { label: '805-UNIMED CAMPINAS', value: '805-UNIMED CAMPINAS' },
  { label: '806-UNIMED FESP', value: '806-UNIMED FESP' },
  { label: '807-UNIMED JUNDIAI', value: '807-UNIMED JUNDIAI' },
  { label: '808-UNIMED LEST FLUMIN', value: '808-UNIMED LEST FLUMIN' },
  { label: '809-UNIMED LITORAL', value: '809-UNIMED LITORAL' },
  { label: '810-UNIMED RIO', value: '810-UNIMED RIO' },
  { label: "811-UNIMED SANTA BÁRBADA D'", value: "811-UNIMED SANTA BÁRBADA D'" },
  { label: '812-UNIMED SEGURO SAUDE S/A', value: '812-UNIMED SEGURO SAUDE S/A' },
  { label: '813-UNISF', value: '813-UNISF' },
  { label: '814-UNIVIDA SAÚDE', value: '814-UNIVIDA SAÚDE' },
  { label: '815-URMES', value: '815-URMES' },
  { label: '816-USISAÚDE', value: '816-USISAÚDE' },
  {
    label: '817-USISAUDE - FUND. SÃO FRANCISCO XAVIER',
    value: '817-USISAUDE - FUND. SÃO FRANCISCO XAVIER',
  },
  { label: '818-VALE SAÚDE', value: '818-VALE SAÚDE' },
  { label: '819-VASCO DA GAMA', value: '819-VASCO DA GAMA' },
  { label: '820-VERIDAS BPO', value: '820-VERIDAS BPO' },
  { label: '821-VIBRA ENERGIA S.A', value: '821-VIBRA ENERGIA S.A' },
  {
    label: '822-VISION MED ASSITENCIA MEDICA LTDA',
    value: '822-VISION MED ASSITENCIA MEDICA LTDA',
  },
  { label: '823-VIVEST', value: '823-VIVEST' },
  {
    label: '824-VLG - SERVIÇOS &amp; SOLUÇÕES EM SAÚDE EIREL',
    value: '824-VLG - SERVIÇOS &amp; SOLUÇÕES EM SAÚDE EIREL',
  },
  { label: '825-VOCÊ TOTAL', value: '825-VOCÊ TOTAL' },
  {
    label: '826-WMM SOLUTIONS LTDA (WORLD MEDICAL)',
    value: '826-WMM SOLUTIONS LTDA (WORLD MEDICAL)',
  },
  {
    label: '827-WORLD TRAVEL ASSIST DO BRASIL LTDA',
    value: '827-WORLD TRAVEL ASSIST DO BRASIL LTDA',
  },
  { label: '828-WTA - ASSIST DO BRASIL', value: '828-WTA - ASSIST DO BRASIL' },
  { label: '94-CODEB - COOP. EV. BELEM', value: '94-CODEB - COOP. EV. BELEM' },
  { label: '95-UNIOSP - SAO LUIS', value: '95-UNIOSP - SAO LUIS' },
  { label: '96-SAUDE EXCELSIOR NATAL', value: '96-SAUDE EXCELSIOR NATAL' },
  { label: '97-POLICLINICA SANTA CLARA', value: '97-POLICLINICA SANTA CLARA' },
  { label: '98-UNIVERSO SAUDE - SP', value: '98-UNIVERSO SAUDE - SP' },
  { label: '99-SAMCIL PLANOS DE SAUDE', value: '99-SAMCIL PLANOS DE SAUDE' },
]
