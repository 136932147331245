import { useMutation } from '@tanstack/react-query'

import { api } from '@/App/clients/http'

type CreateCompany = {
  internalId: string
  insurerId: string
  holderInternalId: string | null | undefined
}

async function createOrderCompany({ internalId, insurerId, holderInternalId }: CreateCompany) {
  await api.post(
    `${import.meta.env.VITE_BLISS_ORDER_JOB_API_URL}/order/create/company`,
    {
      internalId,
      insurerId,
      holderInternalId,
    },
    {
      headers: {
        'x-api-key': import.meta.env.VITE_BLISS_ORDER_JOB_API_KEY,
        'Content-Type': 'application/json',
      },
    },
  )
}

export const useCreateOrderCompany = () => {
  return useMutation({
    mutationKey: ['createCompany'],
    mutationFn: createOrderCompany,
  })
}
